import { Image, IStackStyles, Stack } from '@fluentui/react';
import React from 'react';

const AuthPage: React.FunctionComponent<{}> = (props) => {

    const stackStyles: Partial<IStackStyles> = {
        root: {
            alignItems: 'center',
            height: '100vh',
            justifyContent: 'center',
            maxHeight: '640px',
            maxWidth: '320px',
            margin: 'auto'
        }
    };

  return (
    <Stack styles={stackStyles}>
        <Stack>
            <Image src={'./logo.png'} />
            {props.children}
        </Stack>
    </Stack>
  );
}

export default AuthPage;